<template>
  <div style="page-break-inside: avoid;">
    <v-col>
      <v-row v-if="!isEmpty(getHeatMapConfig)" class="heatmap-section">
        <v-col cols="12">
          <div class="heatmap-section__title mb-4">
            Open Rate Heatmap
          </div>
          <PdfUSAHeatmap
            :config="getHeatMapConfig"
            :legend-groups="legendGroups"
            unit="%"
            :map-id="heatmapID"
            :is-format-number="false"
          />
        </v-col>
      </v-row>
    </v-col>
  </div>
</template>
<script>
import { isEmpty } from "lodash";
import Helpers from '@/mixins/helpers';
import PdfUSAHeatmap from "@/sharedComponents/charts/PdfUSAHeatmap";

export default {
  components: {
    PdfUSAHeatmap,
  },
  mixins: [Helpers],
  props: {
    report: {
      type: Object,
      default: () => {},
    },
    color: {
      type: String,
      default: '',
    },
  },
  computed: {
    heatmapID() {
      return this.report.campaignId + "s" + this.report.subjectId ?? 0;
    },
    legendGroups() {
      if (!this.getHeatMapConfig.length) {
        return [];
      }

      let max = Math.max(...this.getHeatMapConfig.map(i => i.count));

      let step = Math.floor(max / 4);

      const groups = [];
      const colors = [
        this.color + "40",
        this.color + "80",
        this.color + "bf",
        this.color + "ff",
      ];
      for (let i = 0; i < 4; i++) {
        if (max <= ((i + 1) * step)) {
          groups.push({
            min: i * step,
            max,
            color: colors[i],
          });
          break;
        }

        let currMax = ((i + 1) * step) - 1;
        if (i === 0) {
          currMax = step -1;
        } else if (i === 3) {
          currMax = max;
        }

        groups.push({
          min: i * step,
          max: currMax,
          color: colors[i],
        })
      }

      return groups;
    },
    getHeatMapConfig() {
      if (Object.entries(this.report.stateHeatmap ?? {}).length == 0) {
        return {}
      }

      return Object.entries(this.report.stateHeatmap).map(([key, value]) => ({
        state: key,
        count: value?.uniqueOpenRate ?? 0,
      })).filter(({ count }) => count > 0);
    },
    /**
    * Return best performant states (8 states)
    */
    getBestPerformingStates() {
      return this.getStatesByPerformance(this.report.stateHeatmap).slice(0, 8);
    },
    /**
    * Return best performant states (8 states)
    */
    getWorstPerformingStates() {
      const statesByPerformance = this.getStatesByPerformance(this.report.stateHeatmap);
      return statesByPerformance.slice(statesByPerformance.length - 8, statesByPerformance.length)
        .reverse().filter(el => !this.getBestPerformingStates.includes(el));
    },
  },
  methods: {
    getStatesByPerformance(statesList) {
       /**
       * Sort states by points
       */
      const sortedStates = Object.entries(statesList)
        .sort(([,a],[,b]) => b.uniqueOpenRate - a.uniqueOpenRate)
        .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});

      /**
       * Extract states name by performance
       */
      return Object.entries(sortedStates).flatMap(([key]) => this.getStateName(key) ? this.getStateName(key) : []);
    },
    isEmpty: isEmpty,
  }
}
</script>

<style lang="scss" scoped>
.heatmap-section {
  &__title, &__state {
    font-family: "Open Sans", sans-serif;
    font-size: 12px !important;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #241c15;
  }

  &__state {
    color: #66788e;
  }
}
</style>
