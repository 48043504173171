<template>
  <div class="links-clicked-table mb-4">
    <div class="report-group-label px-4 mb-2" :style="`color: ${color}`">
      {{ groupLabel }}
    </div>

    <v-simple-table>
      <thead>
        <tr class="links-clicked-table__header">
          <th>Link</th>
          <th>Click Count</th>
        </tr>
      </thead>
      <tbody class="links-clicked-table__content">
        <template v-if="linksClicked.length">
          <tr v-for="(item, i) in linksClicked" :key="i">
            <td>{{ item.url }}</td>
            <td>{{ item.clicks }}</td>
          </tr>
        </template>
        <template v-else>
          <tr>
            <td colspan="2" class="text-center">
              No results available.
            </td>
          </tr>
        </template>
      </tbody>
    </v-simple-table>
  </div>
</template>

<script>
import datesMixin from "@/utils/datesMixin.js";

export default {
  name: "LinksClickedTable",
  mixins: [datesMixin],
  props: {
    report: {
      type: Object,
      default: () => {},
    },
    groupLabel: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: '',
    },
  },
  computed: {
    linksClicked() {
      return this.report.linksClicked ?? [];
    }
  }
};
</script>

<style lang="scss" scoped>
.links-clicked-table {
  &__header {
    font-size: 13px !important;
    color: #241c15 !important;
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    th {
      font-size: 13px !important;
      color: #241c15 !important;
      font-family: "Open Sans", sans-serif;
      font-weight: bold;
    }
  }
  .v-menu-button:focus {
    background: #2b84eb !important;
    opacity: 1;
    .v-btn__content {
      color: white;
    }
  }
  &__text {
    font-size: 13px !important;
    color: #241c15 !important;
    font-family: "Open Sans", sans-serif;

    &--blue {
      font-size: 13px !important;
      color: #2b84eb !important;
      font-weight: 600;
      font-family: "Open Sans", sans-serif;
    }
  }
  .star-icon {
    position: absolute;
    margin-left: -35px;
    margin-top: 3px;
  }
  .align-button-to-right {
    text-align: right;
    width: 150px;
  }
  .toggle-to-right {
    margin-left: 6vw;
  }

  &__content {
    tr td {
      font-size: 13px !important;
      color: #241c15 !important;
      font-family: "Open Sans", sans-serif !important;
      * {
        font-size: 13px;
        color: #241c15;
        font-family: "Open Sans", sans-serif;
      }
    }
  }

  .percentage-display-box {
    position: absolute;
    padding: 2px 6px;
    border-radius: 2px;
    box-shadow: 0 1px 9px 0 rgba(43, 132, 235, 0.41);
    border: solid 1.5px #2b84eb;
    background-color: #fff;
    color: #2b84eb;
    transition: 0.2s cubic-bezier(0.4, 0, 0.6, 1);
  }
  ::v-deep .v-progress-linear {
    // Allows percentage box to be seen
    overflow: visible;
  }
  ::v-deep .v-progress-linear__determinate {
    background-image: linear-gradient(to left, #2b84eb, #63abff);
  }
  ::v-deep .v-data-table {
    thead {
      tr {
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.09) !important;
      }
      tr > th {
        border-top: solid 1px #dee7ee;
        border-bottom: solid 1px #dee7ee !important;
      }
      tr > th:first-child {
        border-left: solid 1px #dee7ee;
        border-top-left-radius: 4px;
      }
      tr > th:last-child {
        border-right: solid 1px #dee7ee;
        border-top-right-radius: 4px;
      }
    }
    tbody {
      // table border radiuses
      tr:last-child > td:first-child {
        border-bottom-left-radius: 4px;
      }
      tr:last-child > td:last-child {
        border-bottom-right-radius: 4px;
      }
      // table border colors
      tr > td {
        border-bottom: solid 1px #dee7ee;
      }
      tr > td:first-child {
        border-left: solid 1px #dee7ee;
      }
      tr > td:last-child {
        border-right: solid 1px #dee7ee;
      }

      tr:hover {
        background: #f2faff !important;
      }
    }
  }
}

.download-link {
  color: #2b84eb !important;
  text-decoration: none;
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}
</style>
