<template>
  <div class="report-background pa-7">
    <div
      v-if="reports"
      class="report-content px-0 py-6 pb_before"
    >
      <!-- General Information -->
      <div
        v-for="report in reports"
        :key="report.campaignId"
        class="d-flex justify-space-between align-center px-1 mb-6 nobreak pb_after"
      >
        <v-row>
          <v-col cols="6">
            <div>
              <h3>{{ pdfSettings.pdfReportName }}</h3>
              <div class="report-name">
                Generated: {{ getDateWithTime(requestDate) }}
              </div>
              <div class="report-name">
                {{ report.sends }} recipients
              </div>
              <StatusIcon :type="report.campaignStatus" />
            </div>
          </v-col>
          <v-col cols="2">
            <div class="float-right">
              <div v-if="pdfSettings.isShowEmailImage && emailPreviewImg(report)">
                <img
                  :src="emailPreviewImg(report)"
                  alt="email creative image"
                  style="max-width: 250px; max-height: 90px;"
                >
              </div>
            </div>
          </v-col>
          <v-col cols="4">
            <div class="float-right" style="line-height:1">
              <div class="campaign-header__title">
                {{ report.campaignName }}
              </div>
              <div>
                <span class="campaign-metrics__label">Subject: </span>
                <span class="campaign-header__preheader">{{ report.subject }}</span>
              </div>
              <div v-if="report.previewText">
                <span class="campaign-metrics__label">Preview Text: </span>
                <span class="campaign-header__preheader">{{ report.previewText }}</span>
              </div>
              <div v-if="report.fromLine">
                <span class="campaign-metrics__label">From: </span>
                <span class="campaign-header__preheader">{{ report.fromLine.fromName }}	&#60;{{ report.fromLine.fromEmail }}&#62;</span>
              </div>
              <div v-if="report.firstSendDate">
                <span class="campaign-metrics__label">First send date: </span>
                <span class="campaign-header__preheader">{{ getDateWithTime(report.firstSendDate) }}</span>
              </div>
            </div>
          </v-col>
          <v-col cols="12">
            <v-divider />
            <!-- Email metrics -->
            <div v-if="pdfSettings.isShowEmailMetrics">
              <h4 class="metric-title">
                Email Metrics
              </h4>
              <div class="campaign-metrics">
                <PdfEmailMetrics
                  :key="emailMetricsKey(report.subjectId)"
                  :report-key="emailMetricsKey(report.subjectId)"
                  :data="emailMetricsData(report)"
                  :segment-data="getSegmentReportData(report.subjectId)"
                  :group-label="report.groupLabel"
                  :color="report.color"
                  :best-open-rate="checkIfBestOpenRate(reports, report)"
                  :best-click-rate="checkIfBestClickRate(reports, report)"
                />
              </div>
            </div>

            <!-- KPI Performance -->
            <div v-if="pdfSettings.isShowKPI">
              <div class="outline-box mt-2">
                <h4 class="metric-title">
                  KPI Performance
                </h4>
                <PdfKPIPerformance :report="report" />
              </div>
            </div>
            <v-divider />

            <!-- Email Open Rate Heatmap and Best/Worst Performing States  -->
            <div class="outline-box">
              <v-row>
                <v-col v-if="pdfSettings.isShowHeatmap" cols="6">
                  <PdfHeatmapStats
                    :key="report.subjectId"
                    :report="report"
                    :color="report.color"
                  />
                </v-col>
                <v-col v-if="pdfSettings.isShowTopLinks" cols="6">
                  <h4 class="metric-title">
                    Top Links
                  </h4>
                  <div class="simple-table-div">
                    <v-simple-table>
                      <thead>
                        <tr>
                          <th>Url</th>
                          <th>Clicks</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, i) in report.linksClicked" :key="i">
                          <td style="max-width: 250px">
                            <a :href="item.url">{{ item.url }}</a>
                          </td>
                          <td>{{ item.clicks }}</td>
                        </tr>
                      </tbody>
                    </v-simple-table>
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row>
      </div>
      <div
        v-if="reports.length > 1"
        class="d-flex justify-space-between align-center px-1 mb-6"
      >
        <div v-if="pdfSettings.isShowEmailMetrics">
          <h4 class="metric-title">
            Total Email Metrics
          </h4>
          <div class="campaign-metrics">
            <PdfEmailMetrics
              :key="emailMetricsKey(totalReport.subjectId)"
              :data="emailMetricsData(totalReport)"
              :color="totalReport.color"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EmailReportMixin from '@/mixins/email-report-mixin';
import Helpers from '@/mixins/helpers';
import PdfEmailMetrics from './PdfEmailMetrics';
import PdfHeatmapStats from './PdfHeatmapStats';
import PdfKPIPerformance from './PdfKPIPerformance';
import datesMixin from "@/utils/datesMixin.js";
import StatusIcon from "@/views/Campaigns/components/StatusIcon";

export default {
  components: {
    PdfEmailMetrics,
    PdfHeatmapStats,
    PdfKPIPerformance,
    StatusIcon,
  },
  mixins: [EmailReportMixin, Helpers, datesMixin],
  props: {
    reports: {
      type: Array,
      default: () => [],
    },
    segmentReports: {
      type: Object,
        default: () => {},
    },
    requestDate: {
      default: () => '',
    },
    pdfSettings: {
      default: () => '',
    },
  },
  data() {
    return {
      isShowModal: true,
      totalReport: {
        subjectId: 1,
        color: '070707FF',
      },
    }
  },
  watch: {
    reports: {
      deep: true,
      handler() {
        this.createTotals();
      },
    },
  },
  created() {
    this.createTotals();
  },
  methods: {
    getSegmentReportData(subjectId) {
      return this.segmentReports[subjectId] ?? {};
    },
    emailPreviewImg(report) {
      return report?.imageFileUrls?.small ?? null;
    },
    emailMetricsKey(subjectId) {
      return `email-metrics-${subjectId}`;
    },
    createTotals() {
      this.totalReport.sends = 0;
      this.totalReport.hardBounces = 0;
      this.totalReport.softBounces = 0;
      this.totalReport.spamComplaints = 0;
      this.totalReport.uniqueClicks = 0;
      this.totalReport.uniqueOpens = 0;
      this.totalReport.unsubscribes = 0;
      this.totalReport.clicks = 0;

      if (this.reports.length > 1) {
        this.reports.forEach((report) => {
          this.totalReport.sends += parseInt(report.sends);
          this.totalReport.hardBounces += parseInt(report.hardBounces);
          this.totalReport.softBounces += parseInt(report.softBounces);
          this.totalReport.spamComplaints += parseInt(report.spamComplaints);
          this.totalReport.uniqueClicks += parseInt(report.uniqueClicks);
          this.totalReport.uniqueOpens += parseInt(report.uniqueOpens);
          this.totalReport.unsubscribes += parseInt(report.unsubscribes);
          this.totalReport.clicks += parseInt(report.clicks);
        });
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.report-background {
  background: #ecf1f5;
  width: 100%;

  .report-content {
    background: white;
  }
}

.campaign {
  &__identifier {
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }
}

.campaign-header {
  &__title {
    margin-top: 10px;
    font-size: 12px;
  }
  &__title, &__preheader, &__recipients {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #241c15;
  }
  &__preheader {
    font-size: 10px;
    font-weight: normal;
    color: #66788e;
  }
  &__recipients {
    font-size: 10px;
    font-weight: normal;
    text-align: right;
    color: #66788e;
    .active {
      font-weight: 600;
      color: #1f9f43;
    }
  }
}
.campaign-metrics {
  margin-left: 15px;
}

.campaign-metrics__label {
  font-family: "Open Sans", sans-serif;
  font-size: 10px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #241c15;
}

.metric-title {
  font-family: "Open Sans", sans-serif;
  font-size: 12px !important;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #241c15;
}
.simple-table-div {
  th {
    font-size: 10px !important;
  }
  td {
    font-size: 10px !important;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

  .outline-box {
    font-family: "Open Sans", sans-serif;
  }
  @media print {
    .report-name {
      font-size: 10px;
    }
    .noprint {
      visibility: hidden;
      display: none;
    }
    .pagebreak { page-break-before: always; } /* page-break-after works, as well */
    .pagebreak {
      clear: both;
      page-break-after: always;
    }
    .pb_after {
      page-break-after: always !important;
    }
  }
  .nobreak {
    page-break-inside: avoid;
    break-inside: avoid
  }
  .print-report {
    margin: 0 !important;
  }
  .campaign-metrics__percentage {
    font-size: 10px !important;
  }
  .report-content {
    margin-top: -55px !important;
    margin-left: -20px !important;
    padding-right: 0 !important;
  }
  .metric-title {
    padding: 10px !important;
  }
  @media all {
    .pagebreak { display: none; }
  }
</style>