<template>
  <div class="kpi-section p-2">
    <v-row class="justify-center px-5">
      <v-col
        v-for="chart in kpiCharts"
        :key="chart.title"
        cols="12"
        lg="6"
        class="relative px-5 my-4"
      >
        <BarChart
          :styles="chartStyles(true)"
          :chart-data="chart.data.lineData"
          :options="chartOptions(chart.metricType, true)"
        />
        <BarChart
          id="canvas"
          :styles="chartStyles(false)"
          :chart-data="chart.data.barData"
          :options="chartOptions(chart.metricType, false)"
          @update:ref="updateChart"
        />
        <div class="kpi-section__chart-title text-center mt-2">
          {{ chart.title }}
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import EmailReportMixin from '@/mixins/email-report-mixin';
import BarChart from '@/sharedComponents/charts/BarChart';

export default {
  components: {
    BarChart
  },
  mixins: [EmailReportMixin],
  props: {
    reports: {
      type: Array,
      default: () => [],
    },
  }, 
  data: () => ({
    canvas: null,
  }),
  computed: {
    emailKpi() {
      return this.$store.getters["user/account"]?.kpiSettings?.email;
    },
    kpiCharts() {
      return [
        {
          title: "Open Rate vs Benchmark",
          metricType: "Open Rate",
          data: this.getChartData("Open Rate", this.emailKpi?.min_open_rate, this.emailKpi?.max_open_rate),
        },
        {
          title: "Click Rate vs Benchmark",
          metricType: "Click Rate",
          data: this.getChartData("Click Rate", this.emailKpi?.min_click_rate, this.emailKpi?.max_click_rate),
        },
        {
          title: "Click To Open Rate vs Benchmark",
          metricType: "Click To Open Rate",
          data: this.getChartData("Click To Open Rate", this.emailKpi?.min_ctor, this.emailKpi?.max_ctor),
        },
      ]
    },
  },
  methods: {
    chartStyles(isRelative) {
      return {
        width: isRelative ? 'auto' : 'calc(100% - 40px)',
        height: '200px',
        position: isRelative ? 'relative' : 'absolute',
        top: isRelative ? 'unset' : '12px',
        left: isRelative ? 'unset' : '30px',
      }
    },
    chartOptions(metricType, showTicks) {
      return {
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          enabled: false,
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              stepSize: 7.5,
              max: this.getMaxYAxes(metricType),
              display: showTicks,
            },
            gridLines: {
              display: showTicks,
            },
          }],  
          xAxes: [{
            ticks: {
              min: 'first',
              max: 'last',
              display: false,
            },
            gridLines: {
              display: false,
            },
          }],
        },
        legend: {
          display: false,
        },
        layout: {
          padding: {
            right: 140,
            top: 20,
          }
        }
      }
    },
    getMaxYAxes(metricType) {
      const maxValue = Math.max(...Object.values(this.reports).map(report => this.emailMetricsData(report, false)[metricType].percentage ?? 0));
      return Math.ceil(maxValue / 7.5) * 7.5;
    },
    getGradientColor(index) {
      const palettes = [
        ['#2b84eb', '#63abff'],
        ['#23949e', '#44bac4'],
        ['#6a7ee0', '#8292e2'],
        ['#da762a', '#f7b27e'],
        ['#dd6060', '#fa8484'],
      ];
      if (this.canvas) {
        const gradient = this.canvas.getContext("2d").createLinearGradient(0, 0, 0, 800);
        gradient.addColorStop(0, palettes[index][0]);
        gradient.addColorStop(1, palettes[index][1]);
        return gradient;
      }
      return ['#80b6f4'];
    },
    getChartData(metricType, lineMetricMin, lineMetricMax) {
      return {
        barData: {
          labels: ['mid'],
          datasets: [
            ...Object.values(this.reports).map((report, i) => ({
              ...this.getBarChartData(this.getGradientColor(i), this.emailMetricsData(report, false)[metricType], report.color)
            })),
          ]
        },
        lineData: {
          labels: ['outl', 'first', 'mid', 'last', 'outr'],
          datasets: [
            {
              ...this.getLineChartData(lineMetricMin),
            },
            {
              ...this.getLineChartData(lineMetricMax, true),
            },
          ]
        }
      }
    },
    getBarChartData(gradientStroke, metric, color) {
      return {
        type: 'bar',
        data: [metric?.percentage != "NaN" ? metric.percentage : 0],
        maxBarThickness: 60,
        backgroundColor: gradientStroke ? gradientStroke : ['#80b6f4'],
        datalabels: {
          color: [
            metric?.percentage != "NaN" ? color : 'transparent',
          ],
          align: 'end',
          anchor: 'end',
          textStrokeWidth: 0.7,
          formatter: function (value) {
            return value + '%';
          },
          font: {
            weight: 'bold',
            size: 13,
          }
        }
      }
    },
    getLineChartData(metric, isMax) {
      return {
        type: 'line', 
        data: Array(5).fill(metric),
        fill: false,
        borderWidth: 2,
        borderColor: '#2b84eb',
        borderDash: [10],
        lineTension: 0,
        steppedLine: true,
        pointRadius: false,
        pointHitRadius: false,
        pointHoverRadius: false,
        datalabels: {
          align: isMax ? -30 : 30,
          padding: function(context) {
            return {
              top: 0,
              left: 0,
              right: (context.chart.width - 140) / 3,
              bottom: 0,
            };
          },
          color: [
            'transparent',
            'transparent',
            'transparent',
            '#2b84eb',
            'transparent',
          ],
          textStrokeWidth: 0.3,
          formatter: function (value) {
            if(isMax) return 'Max Benchmark ' + value + '%';
            return 'Min Benchmark ' + value + '%';
          },
          font: {
            weight: 'bold',
            size: 13,
          }
        },
      }
    },
    updateChart({ canvas, id }) {
      this[id] = canvas;
    },
  }
}
</script>

<style lang="scss" scoped>
.kpi-section {
  width: 100%;

  &__title, &__chart-title {
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #241c15;
  }
}

.relative {
  position: relative;
}
</style>
