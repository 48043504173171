<template>
  <v-col>
    <v-row class="flex-nowrap mb-6">
      <div class="report-group-label" :style="`color: ${color}`">
        {{ groupLabel }}
      </div>
      <v-row>
        <v-col 
          v-for="(metric_value, metric_key) in deviceMetrics"
          :key="metric_key"
          cols="12"
          sm="6"
        >
          <div class="campaign-device__title">
            {{ metric_key }}
          </div>
          <div class="d-flex flex-column flex-md-row align-center pl-lg-4">
            <div class="pl-2 pl-lg-4">
              <div
                v-for=" { icon, percentage, statistic, unit } in metric_value"
                :key="unit"
                class="d-flex align-center campaign-device__text mt-5"
              >
                <div class="d-flex">
                  <v-icon dense class="campaign-device__type">
                    {{ icon }}
                  </v-icon>
                  <span class="ml-2">{{ unit }}</span>
                  <span class="indicator ml-2" :style="`color: ${color}`">
                    {{ statistic }} ({{ percentage }}%)
                  </span>
                </div>
              </div>
            </div>
            <div class="pie-chart-widget ml-lg-8" style="width: 180px">
              <PieChartWidget :config="chartConfig" :data="getChartData(metric_value)" :height="120" />
            </div>
          </div>
        </v-col>
      </v-row>
    </v-row>
    <v-divider />
  </v-col>
</template>

<script>
import PieChartWidget from "@/sharedComponents/widgets/PieChartWidget";

export default {
  components: {
    PieChartWidget
  },
  props: {
    report: {
      type: Object,
      default: () => {},
    },
    groupLabel: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: '',
    },
  },
  computed: {
    openPerDeviceMobile() {
      if (!this.report.deviceStatistics?.mobile?.uniqueOpens && !this.report.deviceStatistics?.smartphone?.uniqueOpens) return 0

      if (!this.report.deviceStatistics?.mobile?.uniqueOpens) return this.report.deviceStatistics.smartphone.uniqueOpens

      if (!this.report.deviceStatistics?.smartphone?.uniqueOpens) return this.report.deviceStatistics.mobile.uniqueOpens

      return this.report.deviceStatistics.smartphone.uniqueOpens + this.report.deviceStatistics.mobile.uniqueOpens
    },
    deviceMetrics() {
      const total = this.openPerDeviceMobile + 
        (this.report.deviceStatistics?.desktop?.uniqueOpens ?? 0) + 
        (this.report.deviceStatistics?.tablet?.uniqueOpens ?? 0) +
        (this.report.deviceStatistics?.["Mobile/Desktop"]?.uniqueOpens ?? 0);

      return {
        "Opens Per Device": [
          {
            icon: "mdi-cellphone",
            unit: "Mobile",
            statistic: this.openPerDeviceMobile,
            percentage: total > 0 ? (this.openPerDeviceMobile / total * 100).toFixed() : 0,
          },
          {
            icon: "mdi-monitor",
            unit: "Desktop",
            statistic: this.report.deviceStatistics?.desktop?.uniqueOpens ?? 0,
            percentage: total > 0 ? ((this.report.deviceStatistics?.desktop?.uniqueOpens ?? 0) / total * 100).toFixed() : 0,
          },
          {
            icon: "mdi-tablet",
            unit: "Tablet",
            statistic: this.report.deviceStatistics?.tablet?.uniqueOpens ?? 0,
            percentage: total > 0 ? ((this.report.deviceStatistics?.tablet?.uniqueOpens ?? 0) / total * 100).toFixed() : 0,
          },
          {
            icon: "mdi-crosshairs-question",
            unit: "Unknown",
            statistic: this.report.deviceStatistics?.["Mobile/Desktop"]?.uniqueOpens ?? 0,
            percentage: total > 0 ? ((this.report.deviceStatistics?.["Mobile/Desktop"]?.uniqueOpens ?? 0) / total * 100).toFixed() : 0,
          },
        ],
      }
    },
    chartConfig() {
      return {
        name: '',
        hideLegend: true,
      };
    },
  },
  methods: {
    getChartData(metricValue) {
      return metricValue.map(({ statistic, unit }) => ({
        total: statistic,
        label: unit,
      }));
    }
  }
}
</script>

<style lang="scss" scoped>
.report-group-label {
  display: flex;
  font-weight: bold;
  margin-left: 0.25rem;
  margin-right: 1rem;
  padding-top: 2.5rem;
}

.campaign-device {
  &__title {
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #241c15;
  }
  &__type {
    color: #66788e;
  }
  &__text {
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #66788e;
    .indicator {
      font-weight: 600;
    }
  }
  > div:not(:first-child) {
    .campaign-device__title {
      display: none;
    }
  }
  > div:last-child {
    hr {
      display: none;
    }
  }
}

.pie-chart-widget {
  .cell {
    border: none !important;
  }
  .widget-block__title {
    display: none;
  }
}
</style>
