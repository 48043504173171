<template>
  <div class="kpi-section p-2">
    <v-row class="justify-center">
      <v-col
        v-for="chart in kpiCharts"
        :key="chart.title"
        cols="4"
        md="6"
        lg="4"
        class="px-10 my-4"
      >
        <BarChart
          id="canvas"
          :styles="chartStyles"
          :chart-data="chart.data"
          :options="chartOptions"
          @update:ref="updateChart"
        />
        <div class="kpi-section__chart-title text-center mt-2">
          {{ chart.title }}
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import EmailReportMixin from '@/mixins/email-report-mixin';
import BarChart from '@/sharedComponents/charts/BarChart';
export default {
  components: {
    BarChart
  },
  mixins: [EmailReportMixin],
  props: {
    report: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    canvas: null,
  }),
  computed: {
    emailKpi() {
      return this.$store.getters["user/account"]?.kpiSettings?.email;
    },
    kpiCharts() {
      return [
        {
          title: "Open Rate",
          data: this.getChartData("Open Rate", this.emailKpi?.min_open_rate, this.emailKpi?.max_open_rate),
        },
        {
          title: "Click Rate",
          data: this.getChartData("Click Rate", this.emailKpi?.min_click_rate, this.emailKpi?.max_click_rate),
        },
        {
          title: "Click To Open Rate",
          data: this.getChartData("Click To Open Rate", this.emailKpi?.min_ctor, this.emailKpi?.max_ctor),
        },
      ]
    },
    chartOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          enabled: false,
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              stepSize: 7.5,
            },
          }],  
          xAxes: [{
            ticks: {
              min: 'first',
              max: 'last',
              display: false,
            },
            gridLines: {
              display: false,
            },
          }],
        },
        legend: {
          display: false,
        },
        layout: {
          padding: {
            right: 100,
            top: 20,
          }
        }
      }
    },
    chartStyles() {
      return {
        height: '160px',
        width: '260px',
        position: 'relative',
      }
    },
  },
  methods: {
    getGradientColor(index) {
      const palettes = [
        ['#2b84eb', '#63abff'],
        ['#23949e', '#44bac4'],
        ['#6a7ee0', '#8292e2'],
        ['#da762a', '#f7b27e'],
        ['#dd6060', '#fa8484'],
      ];
      if (this.canvas) {
        const gradient = this.canvas.getContext("2d").createLinearGradient(0, 0, 0, 800);
        gradient.addColorStop(0, palettes[index][0]);
        gradient.addColorStop(1, palettes[index][1]);
        return gradient;
      }
      return ['#80b6f4'];
    },
    getChartData(metricType, lineMetricMin, lineMetricMax) {
      return {
        labels: ['outl', 'first', 'mid', 'last', 'outr'],
        datasets: [
          {
            ...this.getBarChartData(this.getGradientColor(0), this.emailMetricsData(this.report, false)[metricType], this.report.color)
          },
          {
            ...this.getLineChartData(lineMetricMin),
          },
          {
            ...this.getLineChartData(lineMetricMax, true),
          },
        ]
      }
    },
    getBarChartData(gradientStroke, metric, color) {
      return {
        type: 'bar',
        data: [0, 0, metric?.percentage != "NaN" ? metric.percentage : 0, 0, 0],
        maxBarThickness: 60,
        backgroundColor: gradientStroke ? gradientStroke : ['#80b6f4'],
        datalabels: {
          color: [
            'transparent',
            'transparent',
            metric?.percentage != "NaN" ? color : 'transparent',
            'transparent',
            'transparent',
          ],
          align: 'end',
          anchor: 'end',
          textStrokeWidth: 0.7,
          formatter: function (value) {
            return value + '%';
          },
          font: {
            weight: 'bold',
            size: 10,
          }
        }
      }
    },
    getLineChartData(metric, isMax) {
      return {
        type: 'line', 
        data: Array(5).fill(metric),
        fill: false,
        borderWidth: 2,
        borderColor: '#2b84eb',
        borderDash: [10],
        lineTension: 0,
        steppedLine: true,
        pointRadius: false,
        pointHitRadius: false,
        pointHoverRadius: false,
        datalabels: {
          align: 'right',
          offset: function(context) {
            //  The width of chart minus the grid line and the text of label
            //  divide by number of columns in chart
            //  center so divided by 2
            const columnWidth = (context.chart.width - 120) / 3;
            return columnWidth / 2;
          },
          color: [
            'transparent',
            'transparent',
            'transparent',
            '#2b84eb',
            'transparent',
          ],
          textStrokeWidth: 0.3,
          formatter: function (value) {
            if(isMax) return  value + '%';
            return value + '%';
          },
          font: {
            weight: 'bold',
            size: 10,
          }
        },
      }
    },
    updateChart({ canvas, id }) {
      this[id] = canvas;
    },
  }
}
</script>

<style lang="scss" scoped>
.kpi-section {
  width: 100%;
  &__title, &__chart-title {
    font-size: 10px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #241c15;
  }
}
</style>
