<template>
  <div class="report-background pa-7">
    <div class="report-content px-7 py-6">
      <!-- General Information -->
      <div
        v-for="report in reports"
        :key="'report-' + report.subjectId"
        class="d-flex justify-space-between align-center px-1 mb-6"
      >
        <div>
          <div class="campaign-header__title pb-1">
            <span class="report-group-label mr-3" :style="`color: ${report.color}`">
              {{ report.groupLabel }}
            </span>
            {{ report.campaignName }}
          </div>
          <div class="d-flex">
            <div style="width: 100px">
              <v-img
                max-height="80px"
                contain
                class="cursor-pointer my-2"
                :src="emailPreviewImg(report)"
                lazy-src="@/assets/default-thumbnail.png"
                @click="openPreviewImageDialog(report)"
              >
                <template #placeholder>
                  <v-img
                    src="@/assets/default-thumbnail.png"
                    alt="thumbnail"
                    class="banner-logo"
                    max-height="80px"
                  />
                </template>
              </v-img>
              <p class="campaign-header__label text-center mb-0">
                {{ toCapitalizedWords(report.configuration.nodeType || 'N/A') }}
              </p>
            </div>
            <div class="ml-2">
              <div class="mt-2">
                <span class="campaign-metrics__label">Subject: </span>
                <span class="campaign-header__preheader">{{ report.subject }}</span>
              </div>
              <div v-if="report.previewText" class="mt-1">
                <span class="campaign-metrics__label">Preview Text: </span>
                <span class="campaign-header__preheader">{{ report.previewText }}</span>
              </div>
              <div v-if="report.fromLine" class="mt-1">
                <span class="campaign-metrics__label">From: </span>
                <span class="campaign-header__preheader">{{ report.fromLine.fromName }}	&#60;{{ report.fromLine.fromEmail }}&#62;</span>
              </div>
            </div>
          </div>
        </div>
        <div class="campaign-header__recipients">
          <p class="mb-2">
            <span class="campaign-header__label">Send Date: </span>
            <span class="campaign-header__preheader">{{ report.firstSendDate ? getDate(report.firstSendDate) : 'Not Sent Yet' }}</span>
          </p>
          <p class="mb-0">
            {{ report.sends }} recipients
            <span class="mx-2"> | </span>
            <StatusIcon :type="report.campaignStatus" />
          </p>
        </div>
      </div>

      <v-divider />

      <!-- Email metrics -->
      <div style="page-break-inside: avoid;">
        <h4 class="metric-title mt-8 mb-6 px-4">
          Email Metrics
        </h4>
        <div class="campaign-metrics">
          <EmailMetrics
            v-for="report in reports"
            :key="emailMetricsKey(report.subjectId)"
            :report-key="emailMetricsKey(report.subjectId)"
            :data="emailMetricsData(report)"
            :segment-data="getSegmentReportData(report.subjectId)"
            :group-label="report.groupLabel"
            :color="report.color"
            :best-open-rate="checkIfBestOpenRate(reports, report)"
            :best-click-rate="checkIfBestClickRate(reports, report)"
          />
        </div>
        <v-divider
          v-if="reports.length > 1"
          class="mb-6 mr-2 ml-2"
        />
        <div
          v-if="reports.length > 1"
          class="campaign-metrics"
        >
          <EmailMetrics
            :key="emailMetricsKey(totalReport.subjectId)"
            :data="emailMetricsData(totalReport)"
            :group-label="totalReport.groupLabel"
            :color="totalReport.color"
          />
        </div>
      </div>

      <!-- Clicks & Device metrics -->
      <div class="campaign-device mt-4 noprint">
        <ClicksAndDeviceMetrics
          v-for="report in reports"
          :key="deviceMetricsKey(report.subjectId)"
          :report="report"
          :group-label="report.groupLabel"
          :color="report.color"
        />
      </div>

      <!-- KPI Performance -->
      <div style="page-break-inside: avoid;">
        <div class="outline-box mt-2">
          <div class="metric-title pt-8 pb-4 px-6">
            KPI Performance
          </div>
          <KPIPerformance :reports="reports" />
        </div>
      </div>

      <!-- Email Open Rate Heatmap and Best/Worst Performing States  -->
      <div class="outline-box mt-8">
        <HeatmapStats
          v-for="report in reports"
          :key="'heatmap-' + report.subjectId"
          :report="report"
          :color="report.color"
        />
      </div>

      <!-- Links Clicked  -->
      <div class="mt-8">
        <div class="metric-title pb-4 px-4">
          Links Clicked
        </div>
        <LinksClickedTable
          v-for="report in reports"
          :key="deviceMetricsKey(report.subjectId)"
          :report="report"
          :group-label="report.groupLabel"
          :color="report.color"
        />
      </div>
    </div>

    <v-dialog v-model="previewImageDialog" scrollable>
      <ImagePreviewDialog
        v-if="previewImageDialog"
        :image-url="previewImageUrl"
        :full-image-url="previewFullImageUrl"
        :name="previewImageName"
        @dismiss="previewImageDialog = false"
      />
    </v-dialog>
  </div>
</template>

<script>
import EmailReportMixin from '@/mixins/email-report-mixin';
import Helpers from '@/mixins/helpers';
import ImagePreviewDialog from "@/sharedComponents/ImagePreviewDialog";
import DatesMixin from "@/utils/datesMixin.js";
import ClicksAndDeviceMetrics from './ClicksAndDeviceMetrics';
import EmailMetrics from './EmailMetrics';
import HeatmapStats from './HeatmapStats';
import KPIPerformance from './KPIPerformance';
import LinksClickedTable from './LinksClickedTable';
import StatusIcon from "@/views/Campaigns/components/StatusIcon";

export default {
  components: {
    ImagePreviewDialog,
    ClicksAndDeviceMetrics,
    EmailMetrics,
    HeatmapStats,
    KPIPerformance,
    LinksClickedTable,
    StatusIcon,
  },
  mixins: [DatesMixin, EmailReportMixin, Helpers],
  props: {
    reports: {
      type: Array,
      default: () => [],
    },
    segmentReports: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      previewImageDialog: false,
      previewImageName: null,
      previewImageUrl: null,
      previewFullImageUrl: null,
      totalReport: {
        subjectId: 1,
        groupLabel: 'Totals',
        color: '070707FF',
      },
    };
  },
  watch: {
    reports: {
      deep: true,
      handler() {
        this.createTotals();
      },
    },
  },
  created() {
    this.createTotals();
  },
  methods: {
    emailPreviewImg(report) {
      return report?.imageFileUrls?.small ?? null;
    },
    openPreviewImageDialog(report) {
      if (report?.imageFileUrls && Object.values(report?.imageFileUrls).length > 0) {
        const imageFileUrls = report.imageFileUrls;
        this.previewImageUrl = imageFileUrls.small || imageFileUrls.medium || imageFileUrls.large || imageFileUrls.full;
        this.previewFullImageUrl = imageFileUrls.full || imageFileUrls.large || imageFileUrls.medium || imageFileUrls.small;
        this.previewImageName = report.configuration.name;
        this.previewImageDialog = !!this.previewImageUrl;
      }
    },
    deviceMetricsKey(subjectId) {
      return `device-metrics-${subjectId}`;
    },
    emailMetricsKey(subjectId) {
      return `email-metrics-${subjectId}`;
    },
    getSegmentReportData(subjectId) {
      return this.segmentReports[subjectId] ?? {};
    },
    toCapitalizedWords(name) {
      const words = name.match(/[A-Za-z][a-z]*/g) || [];
      return words.join(" ");
    },
    createTotals() {
      this.totalReport.sends = 0;
      this.totalReport.hardBounces = 0;
      this.totalReport.softBounces = 0;
      this.totalReport.spamComplaints = 0;
      this.totalReport.uniqueClicks = 0;
      this.totalReport.uniqueOpens = 0;
      this.totalReport.unsubscribes = 0;
      this.totalReport.clicks = 0;

      if (this.reports.length > 1) {
        this.reports.forEach((report) => {
          this.totalReport.sends += parseInt(report.sends);
          this.totalReport.hardBounces += parseInt(report.hardBounces);
          this.totalReport.softBounces += parseInt(report.softBounces);
          this.totalReport.spamComplaints += parseInt(report.spamComplaints);
          this.totalReport.uniqueClicks += parseInt(report.uniqueClicks);
          this.totalReport.uniqueOpens += parseInt(report.uniqueOpens);
          this.totalReport.unsubscribes += parseInt(report.unsubscribes);
          this.totalReport.clicks += parseInt(report.clicks);
        });
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.report-background {
  background: #ecf1f5;
  width: 100%;

  .report-content {
    background: white;
  }
}

.campaign {
  &__identifier {
    font-family: "Open Sans", sans-serif;
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }
}

.campaign-header {
  &__title, &__preheader, &__recipients {
    font-family: "Open Sans", sans-serif;
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #241c15;
  }
  &__preheader {
    font-size: 13px;
    font-weight: normal;
    color: #66788e;
  }
  &__recipients {
    font-size: 13px;
    font-weight: normal;
    text-align: right;
    color: #66788e;
    .active {
      font-weight: 600;
      color: #1f9f43;
    }
  }
}

.campaign-header__label,
.campaign-metrics__label {
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #241c15;
}

.metric-title {
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #241c15;
}

.outline-box {
  border-radius: 4px;
  border: solid 1px #dee7ee;
  font-family: "Open Sans", sans-serif;
}

@media print {
  .noprint {
    visibility: hidden;
    display: none;
  }
  .pagebreak { pagebreak-before: always; } /* pagebreak-after works, as well */
  .pagebreak {
    clear: both;
    pagebreak-after: always;
  }
}
@media all {
  .pagebreak { display: none; }
  .cursor-pointer {
    cursor: pointer;
  }
}
</style>
