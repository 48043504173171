<template>
  <div style="page-break-inside: avoid;">
    <v-col>
      <v-row v-if="!isEmpty(getHeatMapConfig)" class="heatmap-section px-4 py-5">
        <v-col cols="12" lg="6">
          <div class="heatmap-section__title mb-4">
            <span class="report-group-label mr-3" :style="`color: ${report.color}`">
              {{ report.groupLabel }}
            </span>
            Email Heatmap
          </div>
          <USAHeatmap
            :config="getHeatMapConfig"
            :legend-groups="legendGroups"
            unit="%"
            :map-id="heatmapID"
            :is-format-number="false"
          />
        </v-col>
        <v-col cols="12" lg="6">
          <v-row>
            <v-col
              v-if="getBestPerformingStates.length > 0"
              cols="6"
              sm="6"
              md="6"
              lg="6"
              xl="6"
            >
              <div class="heatmap-section__title mb-6">
                Best Performing States
              </div>
              <div
                v-for="(state, pos) in getBestPerformingStates"
                :key="state"
                class="heatmap-section__state d-flex align-center mb-6 pl-5"
              >
                <div :style="`color: ${color}`" class="mr-2">
                  {{ pos + 1 }}
                </div>
                <div>{{ state }}</div>
              </div>
            </v-col>
            <v-col
              v-if="getWorstPerformingStates.length > 0"
              cols="6"
              xs="6"
              sm="6"
              md="6"
              lg="6"
              xl="6"
            >
              <div class="heatmap-section__title mb-6">
                Worst Performing States
              </div>
              <div
                v-for="(state, pos) in getWorstPerformingStates"
                :key="state"
                class="heatmap-section__state d-flex align-center mb-6 pl-5"
              >
                <div :style="`color: ${color}`" class="mr-2">
                  {{ 50 - pos }}
                </div>
                {{ state }}
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </div>
</template>
<script>
import { isEmpty } from "lodash";
import Helpers from '@/mixins/helpers';
import USAHeatmap from "@/sharedComponents/charts/USAHeatmap";

export default {
  components: {
    USAHeatmap,
  },
  mixins: [Helpers],
  props: {
    report: {
      type: Object,
      default: () => {},
    },
    color: {
      type: String,
      default: '',
    },
  },
  computed: {
    heatmapID() {
      return this.report.campaignId + "s" + this.report.subjectId ?? 0;
    },
    legendGroups() {
      if (!this.getHeatMapConfig.length) {
        return [];
      }

      let max = Math.max(...this.getHeatMapConfig.map(i => i.count));

      let step = Math.floor(max / 4);

      const groups = [];
      const colors = [
        this.color + "40",
        this.color + "80",
        this.color + "bf",
        this.color + "ff",
      ];
      for (let i = 0; i < 4; i++) {
        if (max <= ((i + 1) * step)) {
          groups.push({
            min: i * step,
            max,
            color: colors[i],
          });
          break;
        }

        let currMax = ((i + 1) * step) - 1;
        if (i === 0) {
          currMax = step -1;
        } else if (i === 3) {
          currMax = max;
        }

        groups.push({
          min: i * step,
          max: currMax,
          color: colors[i],
        })
      }

      return groups;
    },
    getHeatMapConfig() {
      if (Object.entries(this.report.stateHeatmap ?? {}).length == 0) {
        return {}
      }

      return Object.entries(this.report.stateHeatmap).map(([key, value]) => ({
        state: key,
        count: value?.uniqueOpenRate ?? 0,
      })).filter(item => item.state !== '' && item.count > 0);
    },
    /**
    * Return best performant states (8 states)
    */
    getBestPerformingStates() {
      return this.getStatesByPerformance(this.report.stateHeatmap).slice(0, 8);
    },
    /**
    * Return best performant states (8 states)
    */
    getWorstPerformingStates() {
      const statesByPerformance = this.getStatesByPerformance(this.report.stateHeatmap);
      return statesByPerformance.slice(statesByPerformance.length - 8, statesByPerformance.length)
        .reverse().filter(el => !this.getBestPerformingStates.includes(el));
    },
  },
  methods: {
    getStatesByPerformance(statesList) {
       /**
       * Sort states by points
       */
      const sortedStates = Object.entries(statesList)
        .sort(([,a],[,b]) => b.uniqueOpenRate - a.uniqueOpenRate)
        .reduce((r, [k, v]) => ({ ...r, [k]: v }), {});

      /**
       * Extract states name by performance
       */
      return Object.entries(sortedStates).flatMap(([key]) => this.getStateName(key) ? this.getStateName(key) : []);
    },
    isEmpty: isEmpty,
  }
}
</script>

<style lang="scss" scoped>
.heatmap-section {
  &__title, &__state {
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #241c15;
  }

  &__state {
    font-size: 12px;
    color: #66788e;
  }
}
</style>
