<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      width="500"
    >
      <template #activator="{ on, attrs }">
        <v-btn
          class="custom-button custom-button--blue"
          height="34px"
          width="150px"
          depressed
          :block="$vuetify.breakpoint.xsOnly"
          v-bind="attrs"
          v-on="on"
        >
          Select Email Sends
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="creatives-title py-5">
          Select Email Sends - {{ selectedCount }}/{{ maxSelections }} selected
        </v-card-title>

        <v-card-text>
          <div class="px-6">
            <SearchField v-model="search" class="mb-3" placeholder="Search for email sends" @search="searchFocus = $event" />
          </div>
          <v-divider />
          <v-expansion-panels accordion flat multiple>
            <v-expansion-panel
              v-for="campaignGroup in 
                campaigns.filter(campaign => campaign.campaign.name.toUpperCase().indexOf(searchFocus.toUpperCase()) > -1)"
              :key="campaignGroup.campaign.id"
            >
              <div class="d-flex align-center">
                <div class="ml-6 align-self-start">
                  <v-simple-checkbox
                    v-model="campaignGroup.selected"
                    v-ripple
                    color="primary"
                    class="mt-3 campaign-checkbox"
                    :disabled="(maxSelections - selectedCount) < campaignGroup.subjects.length && !campaignGroup.selected"
                    @click="selectAllEmailSends(campaignGroup)"
                  />
                </div>
                <div class="expansion-panel__item">
                  <v-expansion-panel-header class="campaign-title">
                    {{ campaignGroup.campaign.name }}
                  </v-expansion-panel-header>
                </div>
              </div>
              <v-expansion-panel-content class="pl-0">
                <template v-if="campaignGroup.subjects.length > 0">
                  <div v-for="(emailSend, index) in campaignGroup.subjects" :key="index">
                    <v-divider />
                    <div class="campaign-creative d-flex align-center py-3 pl-12 pl-md-16">
                      <v-simple-checkbox
                        v-model="emailSend.selected"
                        color="primary"
                        :disabled="selectedCount >= maxSelections && !emailSend.selected"
                        @click="checkIfDisabled(emailSend)"
                      />
                      <div class="d-block">
                        <div>
                          <strong>Action: </strong>
                          {{ emailSend.actionNodeLabel }}
                        </div>
                        <div v-if="emailSend.test">
                          <strong>Test: </strong>
                          {{ emailSend.test }}
                        </div>
                        <div>
                          <strong>Creative: </strong>
                          # {{ emailSend.emailMessage.id }} - {{ emailSend.emailMessage.name }}
                        </div>
                        <div>
                          <strong>Subject: </strong>
                          {{ emailSend.subject }}
                        </div>
                        <div v-if="emailSend.previewText">
                          <strong>Preview Text: </strong>
                          {{ emailSend.previewText }}
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </v-expansion-panel-content>
              <v-divider />
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>

        <v-card-actions class="elevation-8">
          <v-spacer />
          <v-btn
            class="custom-button custom-button--blue ml-sm-5 mb-1 mt-1"
            height="34px"
            width="150px"
            depressed
            @click="dialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import SearchField from "@/sharedComponents/SearchFieldWithButton";

export default {
  components: {
    SearchField,
  },
  props: {
    campaigns: {
      type: Array,
      default: () => ([]),
      required: true,
    },
    maxSelections: {
      type: Number,
      default: 5
    },
  },
  data: () => ({
    dialog: false,
    search: '',
    searchFocus: '',
  }),
  computed: {
    selectedCount() {
      let selected = 0;

      for (let i in this.campaigns) {
        selected += this.campaigns[i].subjects.filter(sub => sub.selected).length;
      }

      return selected;
    },
  },
  methods: {
    selectAllEmailSends(campaignGroup) {
      if ((this.maxSelections - this.selectedCount) < campaignGroup.subjects.length && !campaignGroup.selected) {
        this.$notifier.error(
          'This campaign has more sends than you can currently select. Please select the sends you want to view individually.'
        );

        return;
      }

      this.$emit('selected-campaign', campaignGroup.campaign.id);
    },
    checkIfDisabled(emailSend) {
      if (this.selectedCount >= this.maxSelections && !emailSend.selected) {
        this.$notifier.error(
          'You have selected the max amount of sends allowed. Please unselect other sends first to select this one.'
        );
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.v-dialog > .v-card > .v-card__text {
    padding: 0 0 20px !important;
}
.v-simple-checkbox .v-icon {
    caret-color: #a5a9b8 !important;
}
.creatives {
  &-title {
    font-family: "Open Sans", sans-serif;
    font-size: 16px !important;
    font-weight: 600 !important;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal !important;
    color: #241c15;
  }
}
.campaign {
  &-title {
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #241c15;
  }
  &-creative {
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #241c15;
  }
}
.expansion-panel {
  &__item {
    width: 100%;
  }
}

::v-deep .v-expansion-panel-header {
    padding: 16px 26px 16px 20px !important;
}

::v-deep .v-expansion-panel-content__wrap {
    padding: 0 !important;
}

::v-deep .v-expansion-panel-header .v-expansion-panel-header__icon {
  transition-property: transform; 
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 400ms;
  transform: rotate(0deg);
}

::v-deep .v-expansion-panel-header.v-expansion-panel-header--active .v-expansion-panel-header__icon {
  transform: rotate(-180deg);
}
</style>