export default {
  methods: {
    emailMetricsData(report) {
      return {
        "Delivery Rate": {
          percentage: this.get_delivered_percentage(report),
          total: this.get_delivered(report),
          unit: "deliveries",
        },
        "Open Rate": {
          percentage: this.get_opens_percentage(report),
          total: report.uniqueOpens,
          unit: "opens",
        },
        "Click Rate": {
          percentage: this.get_clicks_percentage(report),
          total: report.uniqueClicks,
          unit: "clicks",
        },
        "Click To Open Rate": {
          percentage: this.get_clicks_to_open_percentage(report),
          total: report.uniqueClicks,
          unit: "clicks",
        },
        "Bounce Rate": {
          percentage: this.get_bounce_percentage(report),
          total: this.get_bounce_rate(report),
          unit: "bounces",
        },
        "Opt-Out Rate": {
          percentage: this.get_unsubscribes_percentage(report),
          total: report.unsubscribes,
          unit: "opt-outs",
        },
        "Complaint Rate": {
          percentage: this.get_complaint_percentage(report),
          total: report.spamComplaints,
          unit: "complaints",
        },
      }
    },
    checkIfBestOpenRate(reports, reportToCheck) {
      if (Object.values(reports).length > 1) {
        const percToCheck = this.get_opens_percentage(reportToCheck);

        let openRates = Object.values(reports).map(report => this.get_opens_percentage(report));
        openRates = openRates.sort((a, b) => Number(b) - Number(a));

        return openRates[0] !== '' && openRates[0] === percToCheck;
      }

      return false;
    },
    checkIfBestClickRate(reports, reportToCheck) {
      if (Object.values(reports).length > 1) {
        const percToCheck = this.get_clicks_percentage(reportToCheck);

        let clickRates = Object.values(reports).map(report => this.get_clicks_percentage(report));
        clickRates = clickRates.sort((a, b) => Number(b) - Number(a));

        return clickRates[0] !== '' && clickRates[0] === percToCheck;
      }

      return false;
    },
    get_delivered(statistics) {
      const sends = parseInt(statistics.sends);
      if (isNaN(sends)) return 0
      let hardBounces = parseInt(statistics.hardBounces);
      if (isNaN(hardBounces)) hardBounces = 0
      let softBounces = parseInt(statistics.softBounces);
      if (isNaN(softBounces)) softBounces = 0
      return sends - hardBounces - softBounces;
    },
    get_bounce_rate(statistics) {
      let hardBounces = parseInt(statistics.hardBounces);
      if (isNaN(hardBounces)) hardBounces = 0;
      let softBounces = parseInt(statistics.softBounces);
      if (isNaN(softBounces)) softBounces = 0;
      return softBounces + hardBounces
    },
    get_complaint_percentage(statistics) {
      const delivered = this.get_delivered(statistics);
      if (isNaN(delivered) || delivered === 0) return '0.0';

      return ((parseInt(statistics.spamComplaints) / parseInt(delivered)) * 100).toFixed(1);
    },
    get_delivered_percentage(statistics) {
      const delivered = this.get_delivered(statistics);
      if (isNaN(delivered) || delivered === 0) return '0.0';

      return ((delivered / parseInt(statistics.sends)) * 100).toFixed(1);
    },
    get_clicks_percentage(statistics) {
      const delivered = this.get_delivered(statistics);

      if (parseInt(statistics.uniqueClicks) === 0 || isNaN(delivered) || delivered === 0) {
        return '0.0';
      }
      return (
        (parseInt(statistics.uniqueClicks) / delivered) *
        100
      ).toFixed(1);
    },
    get_clicks_to_open_percentage(statistics) {
      if (!statistics.uniqueOpens || parseInt(statistics.uniqueOpens) === 0) return '0.0';

      return (
        (parseInt(statistics.uniqueClicks) / statistics.uniqueOpens) *
        100
      ).toFixed(1);
    },
    get_bounce_percentage(statistics) {
      if (parseInt(statistics.sends) === 0) return '0.0';

      return (((parseInt(statistics.softBounces) + parseInt(statistics.hardBounces)) / parseInt(statistics.sends)) * 100).toFixed(1);
    },
    get_unsubscribes_percentage(statistics) {
      const delivered = this.get_delivered(statistics);

      if (parseInt(statistics.unsubscribes) === 0 || isNaN(delivered) || delivered === 0) {
        return '0.0';
      }
      return (
        (parseInt(statistics.unsubscribes) / delivered) *
        100
      ).toFixed(1);
    },
    get_opens_percentage(statistics) {
      const delivered = this.get_delivered(statistics);

      if (parseInt(statistics.uniqueOpens) === 0 || isNaN(delivered) || delivered === 0) {
        return '0.0';
      }
      return (
        (parseInt(statistics.uniqueOpens) / delivered) *
        100
      ).toFixed(1);
    },
  },
};
