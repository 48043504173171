<template>
  <div :id="'usa-heat-map' + mapId" style="width: 350px; height: 200px;" />
</template>

<script>
import 'jqvmap/dist/jqvmap.min.css';

export default {
  name: "PdfUSAHeatmap",
  components: {},
  props: {
    config: {
      default: () => [],
    },
    legendGroups: {
      default: () => [],
    },
    isFormatNumber: {
      type: Boolean,
      default: true
    },
    mapId: {
      type: [String, Number],
      default: "",
    },
    unit: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      defaultColor: "#cce1fa",
      states: {
        "AL": "Alabama",
        "AK": "Alaska",
        "AS": "American Samoa",
        "AZ": "Arizona",
        "AR": "Arkansas",
        "CA": "California",
        "CO": "Colorado",
        "CT": "Connecticut",
        "DE": "Delaware",
        "DC": "District Of Columbia",
        "FM": "Federated States Of Micronesia",
        "FL": "Florida",
        "GA": "Georgia",
        "GU": "Guam",
        "HI": "Hawaii",
        "ID": "Idaho",
        "IL": "Illinois",
        "IN": "Indiana",
        "IA": "Iowa",
        "KS": "Kansas",
        "KY": "Kentucky",
        "LA": "Louisiana",
        "ME": "Maine",
        "MH": "Marshall Islands",
        "MD": "Maryland",
        "MA": "Massachusetts",
        "MI": "Michigan",
        "MN": "Minnesota",
        "MS": "Mississippi",
        "MO": "Missouri",
        "MT": "Montana",
        "NE": "Nebraska",
        "NV": "Nevada",
        "NH": "New Hampshire",
        "NJ": "New Jersey",
        "NM": "New Mexico",
        "NY": "New York",
        "NC": "North Carolina",
        "ND": "North Dakota",
        "MP": "Northern Mariana Islands",
        "OH": "Ohio",
        "OK": "Oklahoma",
        "OR": "Oregon",
        "PW": "Palau",
        "PA": "Pennsylvania",
        "PR": "Puerto Rico",
        "RI": "Rhode Island",
        "SC": "South Carolina",
        "SD": "South Dakota",
        "TN": "Tennessee",
        "TX": "Texas",
        "UT": "Utah",
        "VT": "Vermont",
        "VI": "Virgin Islands",
        "VA": "Virginia",
        "WA": "Washington",
        "WV": "West Virginia",
        "WI": "Wisconsin",
        "WY": "Wyoming",
      },
      countNumbers: {},
    }
  },
  watch: {
    config: {
      deep: true,
      handler() {
        this.reset();
        this.init();
      },
    },
  },
  mounted() {
    require('jqvmap');
    require('jqvmap/dist/maps/jquery.vmap.usa')
    this.init();

    setTimeout(() => {
      this.reset();
      this.init();
    }, 300);
  },
  methods: {
    reset() {
      jQuery('.jqvmap').unbind();
      jQuery(`#usa-heat-map` + this.mapId).unbind().html('');
    },
    init() {
      const statesWithColors = {};
      const countObj = {};

      for (const stateCode of Object.keys(this.states)) {
        const stateData = this.config.find((item) => item.state === stateCode);
        this.countNumbers[stateCode] = this.unit ? (parseInt(stateData?.count || 0) || 0) + this.unit: parseInt(stateData?.count || 0) || 0;
        countObj[stateCode] = parseInt(stateData?.count || 0) || 0;

        statesWithColors[stateCode.toLowerCase()] = this.getColorForCount(
          countObj[stateCode]
        )
      }

      const self = this;
      jQuery(`#usa-heat-map${this.mapId}`).vectorMap({
        map: 'usa_en',
        backgroundColor: null,
        color: '#ffffff',
        enableZoom: false,
        showTooltip: true,
        selectedColor: null,
        hoverColor: '#2980b9',
        colors: statesWithColors,
        onLabelShow: function(_, label, code) {
          const stateCode = code.toUpperCase();
          if (this.isFormatNumber) {
            $(label).html(`${self.states[stateCode]}: ${new Intl.NumberFormat().format(self.countNumbers[stateCode])}`);
          } else {
            $(label).html(`${self.states[stateCode]}: ${self.countNumbers[stateCode]}`);
          }
        },
        onLoad: function() {
          jQuery(window).trigger('resize');
        },
        onRegionClick: async function(_, stateCode) {
          await self.redirectToContactPage(stateCode);
          Array.from(document.getElementsByClassName("jqvmap-label"))
              .forEach((el) => { el.style.display = 'none' })
        },
      });
    },
    getColorForCount(count) {
      if (!this.legendGroups.length) {
        return this.defaultColor;
      }

      const group = this.legendGroups.find((group) => {
        if (group.min === group.max && count === group.min) {
          return true;
        }

        return count >= group.min && count < group.max
      });

      return group ? group.color : this.legendGroups.at(-1).color;
    },
    async redirectToContactPage(stateCode) {
      const resp = await this.$rest.contact.search_collection(
        {},
        {
          criteria: {
            groups: [
              {
                conditions: [
                  {
                    field: 'location.state',
                    operator: 'is',
                    value: stateCode.toUpperCase(),
                  },
                ],
                operator: 'AND',
              },
            ],
            operator: 'AND',
          },
        },
      ).catch(() => {
        this.$store.commit('snackbar/showMessage', {
          content: 'Failed to redirect to the Search Contact page',
          color: 'error',
        });
      });

      if (!resp.data) {
       return;
      }

      await this.$router.push({
        name: "ContactsSearch",
        params: { searchId: resp.data.searchId },
      });
    },
  },
};
</script>
