import { render, staticRenderFns } from "./KPIPerformance.vue?vue&type=template&id=4aee96c7&scoped=true"
import script from "./KPIPerformance.vue?vue&type=script&lang=js"
export * from "./KPIPerformance.vue?vue&type=script&lang=js"
import style0 from "./KPIPerformance.vue?vue&type=style&index=0&id=4aee96c7&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4aee96c7",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VRow})
