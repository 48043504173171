<template>
  <div>
    <v-row>
      <v-col
        v-for="(metric_value, metric_key) in data"
        :key="metric_key"
        class="metric-item"
      >
        <div class="metrics__title">
          {{ metric_key }}
        </div>
        <div class="metrics__percentage" :style="`color: ${color}`">
          {{ metric_value.percentage != "NaN" && metric_value.percentage != '0.0' ? formatPercentage(metric_value.percentage) : "-" }}
        </div>
        <div class="metrics__users">
          {{ metric_value.total }} {{ metric_value.unit }}
        </div>
        <span v-if="isBestRate(metric_key, metric_value)" class="metric-best">
          <v-icon dense color="#eba42b">
            mdi-star
          </v-icon>
          Best {{ metric_key }}
        </span>
      </v-col>
    </v-row>
    <v-row
      v-if="hasSegmentData"
      class="segment-metrics mb-6"
    >
      <v-col cols="12" class="metric-title mb-2">
        <span>Segment Metrics</span>
      </v-col>
      <v-row
        v-for="(segmentReport, segmentId) in segmentData"
        :key="reportKey + '_segment_' + segmentId"
        class="flex-column segment-row mx-0"
      >
        <v-row
          class="ml-1 py-2"
        >
          <v-col cols="12" class="pb-0">
            {{ segmentReport.segmentId }} - {{ segmentReport.segmentName }}
          </v-col>
          <v-col
            v-for="(metric_value, metric_key) in emailMetricsData(segmentReport)"
            :key="reportKey + '_segment_' + segmentId + '_' + metric_key"
            class="metric-item py-2"
          >
            <div class="campaign-metrics__title">
              {{ metric_key }}
            </div>
            <div class="campaign-metrics__percentage" :style="`color: ${color}`">
              {{ metric_value.percentage != "NaN" && metric_value.percentage !== '0.0' ? formatPercentage(metric_value.percentage) : "-" }}
            </div>
            <div class="campaign-metrics__users">
              {{ metric_value.total }} {{ metric_value.unit }}
            </div>
          </v-col>
        </v-row>
      </v-row>
    </v-row>
  </div>
</template>

<script>
import EmailReportMixin from '@/mixins/email-report-mixin';

export default {
  components: {},
  mixins: [EmailReportMixin],
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    segmentData: {
      type: Object,
      default: () => {},
    },
    reportKey: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: '',
    },
    bestOpenRate: {
      type: Boolean,
      default: false,
    },
    bestClickRate: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    segmentCount() {
      return Object.values(this.segmentData).length;
    },
    hasSegmentData() {
      return this.segmentData && this.segmentCount > 0;
    }
  },
  methods: {
    formatPercentage(number) {
      return `${number}%`;
    },
    isBestRate(metricValue) {
      if (metricValue === 'Open Rate') {
        return this.bestOpenRate;
      }
      if (metricValue === 'Click Rate') {
        return this.bestClickRate;
      }
      return false;
    }
  }
}
</script>

<style lang="scss" scoped>
.report-group-label {
  display: flex;
  font-weight: bold;
  margin-left: 0.25rem;
  margin-right: 1rem;
  padding-top: 1rem;
}
.metrics {
  &__title {
    font-family: "Open Sans", sans-serif;
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #241c15;
  }
  &__percentage {
    font-family: "Open Sans", sans-serif;
    font-size: 10px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.65;
    letter-spacing: normal;
  }
  &__users {
    font-family: "Open Sans", sans-serif;
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #66788e;
  }
  > div:not(:first-child) {
    .metrics__title {
      display: none;
    }
  }
  > div:last-child {
    hr {
      display: none;
    }
  }
}
.metric-best {
  display: flex;
  align-items: center;
  color: #eba42b;
  font-size: 10px;
  font-weight: 700;
  margin-top: 0.25rem;

  i {
    font-size: 10px;
    margin-right: 0.25rem;
  }
}
.metric-item {
  width: 130px !important;
}
.metric-title {
  font-family: "Open Sans", sans-serif;
  font-size: 12px !important;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #241c15;
  padding: 10px !important;
}
.segment-row {
  font-size: 10px;
}
.segment-row:nth-child(even) {
  background-color: #eeeeee;
}
</style>
